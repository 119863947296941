<template>
  <v-dialog v-model="open" :fullscreen="!isOnPC" @keyup.esc="open = false">
    <template #activator="slotProps">
      <slot v-bind="slotProps" name="activator"><v-btn v-on="slotProps.on">Show Gallery</v-btn></slot>
    </template>
    <v-sheet color="black" >
      <v-btn absolute top right dark icon :large="isOnPC" @click="open = false"><v-icon>mdi-close</v-icon></v-btn>
      <v-carousel @keyup.right="show++" @keyup.left="show--" @keyup.enter="show++" :hide-delimiters="!isOnPC" show-arrows-on-hover hide-delimiter-background v-model="show" :height="isOnPC ? '80vh' : '100vh'">
        <v-carousel-item v-for="(image, n) in images" :key="image.id" :value="n">
          <v-img height="100%" width="100%" contain :src="image.publicUrl">
            <template #placeholder>
              <div class="d-flex justify-center align-center fill-height">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: 'GalleryShowAllPhotos',

  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    'show-image': {
      value: Number,
      default: 0
    },
    images: {
      type: Array,
      required: true
    }
  },

  computed: {
    open: {
      get () { return this.value },
      set (v) { this.$emit('input', v) }
    },
    show: {
      get () { return this.showImage },
      set (v) {
        this.$emit('update:show-image', v)
      }
    },
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  data () {
    return ({})
  }
}
</script>
