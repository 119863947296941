<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular indeterminate />
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="4" md="3" sm="6" v-for="(image, n) in thumbnails" :key="image.id">
        <v-card @click="showPhoto(n)">
          <Thumbnail show-checkbox :src="image.publicUrl" :contain="contain" :aspect-ratio="aspectRatio" v-model="image.checked" />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="(limit && (photos.length > castedLimit)) || slideshowOpen" align="center" justify="center">
      <SlideShowDialog :show-image.sync="slideshowImage" :images="photos" v-model="slideshowOpen">
        <template #activator="{ on }">
          <v-btn color="primary" v-on="on" large>See All {{ photos.length }} Photos</v-btn>
        </template>
      </SlideShowDialog>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '@/plugins/firebase'
import Thumbnail from './Thumbnail'
import SlideShowDialog from './ShowAllDialog'

export default {
  name: 'FirebaseGallery',

  props: {
    'collection-path': {
      required: true,
      type: String
    },
    limit: String,
    checkboxes: {
      required: false,
      default: () => false
    },
    contain: {
      required: false,
      default: () => false
    },
    'aspect-ratio': {
      required: false,
      default: () => 1
    }
  },

  components: {
    Thumbnail, SlideShowDialog
  },

  computed: {
    checkedImages () {
      return this.$data.photos.filter(photo => photo.checked)
    },
    castedLimit () {
      return parseInt(this.limit)
    },
    thumbnails () {
      return this.$data.photos.slice(0, this.castedLimit)
    }
  },

  data () {
    return ({
      loading: false,
      photos: [],

      slideshowOpen: false,
      slideshowImage: 0
    })
  },

  methods: {
    grabImages (path) {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection(path).get()
          .then(querySnapshot => {
            var images = querySnapshot.docs
              .map(doc => (Object.assign({ id: doc.id }, doc.data())))
            resolve(images)
          })
          .catch(e => { reject(e) })
      })
    },

    checkedChange () {
      this.$emit('change', this.checkedImages)
    },

    showPhoto (n) {
      this.$data.slideshowOpen = true
      this.$data.slideshowImage = n
    }
  },

  async mounted () {
    this.$data.loading = true
    this.$data.photos = await this.grabImages(this.collectionPath)
    this.$data.loading = false
  }
}
</script>
