<template>
  <v-img :src="src" :contain="contain" :aspect-ratio="aspectRatio" style="positive: relative">
    <v-simple-checkbox background-color="white" v-if="showCheckboxes" style="position: absolute; top: 5px; left: 5px" v-model="internalValue" />
  </v-img>
</template>

<script>
export default {
  name: 'GalleryThumbnail',

  props: {
    src: {
      type: String, required: true
    },
    'aspect-ratio': {
      type: Number,
      required: false
    },
    contain: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: Boolean,
      default: () => false
    },
    'show-checkboxes': {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>
